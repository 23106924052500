<template src="./wifi.htm">
 


</template>

<script>
import { mapState } from 'vuex';
import Footer from '@/layouts/footer/Footer.vue';
import SubHeader from '@/layouts/sub_header/Subheader.vue';

export default {
  name: "Wifi",
  components: {
      'footer-component': Footer,
      'sub-header': SubHeader,
  },
  data: () => ({
      valid: true,
      name: '',
      is_admin: '',
      selected: [],
      ids: [],
      dialog: false,
      editMode: false,
      overlay: true,
      wifis: [],
      new_pass: '',
      show1: false,
      initForm: {
        id: '',
        name: '',
        password: '',
      },
      Serial_No_Rules: [
          v => !!v || 'Serial Number is required',
        ],
      rules: {
        required: value => !!value || 'Required.',
        min: value => value.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      table_header: [
        { 
          text: 'Wifi',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Password', value: 'password' },
      ],
      wifi_credential: [],       
  }),
  computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
  watch: {
      dialog (val) {
        val
        //val || this.close()
      },
    },
  methods: {
      
      closeDialog () {
          this.$refs.form.reset(),
          this.dialog=!this.dialog
      },
      handleClick (row){
        console.log("row click")
      },
      AddWifi (){
        this.editMode = false;
      },
      editReceiver(val) {
        this.initForm.name=val.name;
        this.initForm.password=val.password;
        this.initForm.id=val.id;
        this.editMode = true;
        this.dialog = true;

      },
      save() {
        if(this.$refs.form.validate()){
          this.$api
            .put(`/wifi/${this.initForm.id}`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
              this.closeDialog();
              this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            })
        }
      },
      search() {
        this.overlay = true;

        this.$api
          .get('/wifi')
            .then(res => {
                this.wifis = res.data.data;
                this.overlay = false;
            })
            .catch(err => {
                this.overlay = false;
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
      },
      delete_selected (){
        var result = confirm("Are you sure you want to delete?");
        if (result==true) {
          this.ids = this.selected.map(wifi => {
            return wifi.id;
          });

          this.$api
            .post('/wifi/delete', {ids: this.ids})
            .then(res => {
                this.$toast.open({
                    message:  res.data.msg,
                    type: 'success',
                    position: 'bottom'

                }),
                this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
        }
      },
      submit() {
        if(this.$refs.form.validate()){
          console.log('here');
         this.$api
            .post(`/wifi`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
                this.closeDialog();
                this.search();
            })
            .catch(err => {
                this.dialog = false;
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            });

          }
      },

  },
  created(){
      this.overlay = true;
  },
  mounted() {
    this.search();
    this.overlay = false;
    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }

    this.name = this.user.name;
    // this.is_admin = this.user.is_admin;
    this.is_admin = (this.user.is_admin==1 ? true : false);
  },
};
</script>